export enum SocketResponseEvents {
  disconnected = 'disconnected',
  roundFinished = 'roundFinished',
  roundStarted = 'roundStarted',
  gameFinished = 'gameFinished',
  playerAdded = 'playerAdded',
  issueCreated = 'issueCreated',
  currentIssueChanged = 'currentIssueChanged',
  issueDeleted = 'issueDeleted',
  issueUpdated = 'issueUpdated',
  gameCancelled = 'gameCancelled',
  gameStarted = 'gameStarted',
  playerLeft = 'playerLeft',
  playerKicked = 'playerKicked',
  playerKickedByVote = 'playerKickedByVote',
  votingToKickStarted = 'votingToKickStarted',
  messagePosted = 'messagePosted',
  playerAdmitted = 'playerAdmitted',
  playerRejected = 'playerRejected',
  entryRequested = 'entryRequested',
  issueScoreUpdated = 'issueScoreUpdated',
  playerNotKickedByVote = 'playerNotKickedByVote',
}
